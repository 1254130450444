// import Vue from 'vue';
// import Services from '@/config/_axios';
import API_OLD from '@/api/index_old';
import Vue from "vue";
//import i18n from '@/config/_i18n'
import ServicesDeal from "../../config/_axios_deal";
var moment = require('moment');
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const striker = {
  state: () => ({
    strikerList: [],
    skmatch: [],
    selectedStrikerId: '',
    isLoading: true
  }),
  mutations: {
    SET_STRIKER(state, payload) {
      state.strikerList=[];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.signDate?.date, "YYYY-MM-DD HH:mm:ssZ");
        var timeAgo = actionTime.fromNow();
        state.strikerList.push(Object.assign({ time_ago: timeAgo }, payload[key]))
      }
    },
    SET_SKMATCH(state, payload) {
      state.skmatch = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.end_date?.date, "YYYY-MM-DD HH:mm:ssZ");
        var timeAgo = actionTime.fromNow();
        state.skmatch.push(Object.assign({ time_ago: timeAgo }, payload[key]))
      }
    },
    SET_STRIKER_ID(state, payload) {
        state.selectedStrikerId = payload;
    },
    IS_LOADING(state, payload) {
        state.isLoading = payload;
    }
  },
  actions: {
    async GET_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      await ServicesDeal.get(API_OLD.striker, params)
        .then(res => {
          commit('SET_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_STRIKER_TV({ commit }) {
      await ServicesDeal.get(`${API_OLD.striker}?limit=10&order=DESC`)
        .then(res => {
          commit('SET_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_LAST_TRANSACTION({ commit }, params) {
      commit('IS_LOADING', true);
      await ServicesDeal.get(API_OLD.skmatch, params)
        .then(res => {
          commit('SET_SKMATCH', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          commit('IS_LOADING', false);
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async POST_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesDeal.post(API_OLD.striker_order, params);
        console.log("POST_STRIKER: ",res)
        if(res.data.success) Vue.$toast.success(getSuccessServiceMsg(res));
        else Vue.$toast.error(getErrorServiceMsg(res));
      } catch (err) {
        console.log("err: ",err)
        if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('IS_LOADING', false);
      }
    },
    POST_STRIKER_NEW({ commit }, params) {
      commit('IS_LOADING', true);
      ServicesDeal.post(API_OLD.striker_new, params)
        .then(res => {
          console.log("POST_STRIKER_NEW: ",res)
          commit('IS_LOADING', false);
          commit('keeper/statusModal', false, { root: true });
          if(res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('keeper/GET_KEEPER', {root: true});
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    DELETE_STRIKER({ commit }, id) {
      ServicesDeal.post(`${API_OLD.striker_rollback}${id}`)
        .then(res => {
          console.log("DELETE_STRIKER: ", res)
          commit('IS_LOADING', false);
          if(res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('striker/GET_STRIKER', {root: true});
          }
        else Vue.$toast.error(getErrorServiceMsg(res));

        })
        .catch(err => {
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
  },
  namespaced: true
}

export default striker;
