import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global';
import customer from './modules/customer'
import cryptobanks from './modules/cryptobanks'
import currencies from './modules/currencies'
import employees from './modules/employees'
import roles from './modules/roles'
import ips from './modules/ips'
import transactions from './modules/transactions'
import institutions from './modules/institutions'
import striker from './modules/striker'
import keeper from './modules/keeper'
import profile from './modules/profile'
import deposit from './modules/deposit'
import withdraw from './modules/withdraw'
import finance from './modules/finance'
import userquicksearch from './modules/userquicksearch'
import userdetails from './modules/userdetails'
import quicksale from './modules/quicksale'
import cryptoTransaction from './modules/cryptoTransaction'
import depositTransaction from './modules/depositTransaction'
import withdrawTransaction from './modules/withdrawTransaction'
import users from './modules/users'
import customerManagement from './modules/customerManagement'
import permissionsManagement from './modules/permissionsManagement'
import currenciesManagement from './modules/currenciesManagement'
import ratesManagement from './modules/ratesManagement'
import bankManagement from './modules/bankManagement'
import paymentMethodsManagemet from './modules/paymentMethodsManagemet'
import marketsManagement from './modules/marketsManagement';
import spottradeManagement from './modules/spottradeManagement';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    global: global,
    customer: customer,
    cryptobanks: cryptobanks,
    currencies: currencies,
    employees: employees,
    roles: roles,
    ips: ips,
    transactions: transactions,
    institutions: institutions,
    striker: striker,
    keeper: keeper,
    profile,
    deposit,
    withdraw,
    finance,
    userquicksearch,
    userdetails,
    quicksale,
    cryptoTransaction,
    depositTransaction,
    withdrawTransaction,
    users,
    customerManagement,
    permissionsManagement,
    currenciesManagement,
    ratesManagement,
    bankManagement,
    paymentMethodsManagemet,
    marketsManagement,
    spottradeManagement
  }
})
