import axios from 'axios';
import Vue from 'vue';
import i18n from '@/config/_i18n';
import router from '@/router';
import store from '@/store';

const BASE_API_URL = process.env.VUE_APP_CRM_API_URI;
const ServicesOld = axios.create({ baseURL: String(BASE_API_URL) });

let lang = sessionStorage.getItem('lang') || 'en';

const abortController = new AbortController();

ServicesOld.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        config.headers.common['X-Localization'] = lang;
        config.signal = abortController.signal;
        return config;
    },
    error => Promise.reject(...error)
);

ServicesOld.interceptors.response.use(
    (response) => response,
    (error) => {
      let errorMessage = error.response?.data?.message || i18n.t('service.fatal_error');
      if (error.response.status === 401 && error.response.data.message === "Expired JWT Token") {
        Vue.$toast.error(i18n.t('service.token_expired'));
        store.commit("customer/SET_LOGOUT");
        abortController.abort();
        errorMessage = i18n.t('service.token_expired');
      }
      if (error.response.status === 401 && error.response.data.message !== "Expired JWT Token") {
        // window.location = '/no-permission';
        router.push({ name: 'NoPermission' }).catch(() => {});
        errorMessage = i18n.t('service.no_permission');

      }
      if (error.response.status === 404 || error.response.status === 405 || error.response.status === 500) {
        // Vue.$toast.error(i18n.t('service.fatal_error'));
        errorMessage = i18n.t('service.fatal_error');
      }

      throw {
        ...error,
        response: {
          ...error.response,
          data: {
            ...error.response.data,
            message: errorMessage
          }
        }
      };
    }
);

export default ServicesOld;
