import Vue from 'vue';
import ServicesOld from "@/config/_axios_old";
import API_OLD from '@/api/index_old';
import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const quicksale = {
    state: () => ({
        cryptos: [],
        balancesLoading: false,
        balanceByCurrency: 0,
        quickSaleBuySellPrices: {
            priceBuyFormatted: 0,
            priceSellFormatted: 0
        },
        quickSaleTransactionSummaryModal: {
            isVisible: false,
            title: '',
            quantity: '',
            amount: '',
            commission: ''
        },
        historyLoading: false,
        historyData: [],
        historyLinks: {},
        historyMeta: {},
        quicksale: {
            crypto: null,
            currency: null,
            type: 10,
            total: null,
            amount: null,
        },
    }),
    mutations: {
        SET_DATA(state, payload) {
            state[payload.type] = payload.data;
        },
        IS_LOADING(state, payload) {
            state[payload.type] = payload.value;
        },
        SET_QUICKSALE_BUY_SELL_PRICES(state, payload) {
            state.quickSaleBuySellPrices = payload;
        },
        SET_QUICKSALE_TS_MODAL(state, payload) {
            state.quickSaleTransactionSummaryModal = payload;
        },
        SET_QUICKSALE_HISTORY(state, payload) {
            state.historyData = payload.page === 1 ? payload.historyData : [...state.historyData, ...payload.historyData];
            state.historyLinks = payload.historyLinks;
            state.historyMeta = payload.historyMeta;
        },
        CLEAR_FORM(state) {
            state.quicksale.total = null;
            state.quicksale.amount = null;
        },
        SET_CALCULATE(state, payload) {
            if (payload.calcType === 'total') {
              state.quicksale.amount = payload.payload?.amount ? parseFloat(payload.payload?.amount) : 0.00;
            } else {
              state.quicksale.total = payload.payload?.total_decimal ? parseFloat(payload.payload.total_decimal) : 0.00;
            }
        },
    },
    actions: {
        
        async GET_BALANCES_BY_CURRENCY({ state, commit }) {
            commit('IS_LOADING', { type: 'balancesLoading', value: true });
            try {
                const res = await ServicesOld.get(`${API_OLD.finance_balances}/${state.quicksale.currency?.code}`)
                commit('SET_DATA', { type: 'balanceByCurrency', data: res?.data?.data?.data?.available_formatted || `0 ${state.quicksale.currency?.code}` })
            } catch (err) {
                console.log('store/modules/quicksale@GET_BALANCES_BY_CURRENCY error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'balancesLoading', value: false });
            }
        },
        async GET_QUICKSALE_BUY_SELL_PRICES({ state, commit }) {
            try {
                const res = await ServicesOld.get(`finance/quicksale/${state.quicksale.crypto?.code}?base_currency=${state.quicksale.currency?.code}`);
                commit('SET_QUICKSALE_BUY_SELL_PRICES', {
                    priceBuyFormatted: res.data?.price_buy_formatted,
                    priceSellFormatted: res.data?.price_sell_formatted,
                    ...res.data
                })
            } catch (error) {
                console.log(error);
            }
        },
        async QUICKSALE_CALCULATE_ACTION(_, { cryptoCode, base_currency, transactionType = '10', calcType = 'quantity', amount, total }) {
            const formData = {
                type: transactionType,
            }
            calcType === 'quantity' ? formData.amount = amount : formData.total = total;
            const res = await ServicesOld.post(`finance/quicksale/${cryptoCode}/calculate?base_currency=${base_currency}`, formData)
            return res.data;
        },
        POST_CALCULATE({ state, commit }, params) {
            const data = {
              type: params.buy
            };
            if (params.calcType === 'amount') data.amount = state.quicksale.amount;
            if (params.calcType === 'total') data.total = state.quicksale.total;            
            const endpoint = `finance/quicksale/${state.quicksale.crypto?.code}/calculate?base_currency=${state.quicksale.currency?.code}`;
            ServicesOld.post(endpoint, data)
              .then((res) => {
                commit('SET_CALCULATE', { calcType: params.calcType, payload: res.data });
              })
              .catch((err) => {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
              });
        },
        async POST_BUY({ commit, dispatch }, { formData = {} }) {
            try {
                const res = await ServicesOld.post(`finance/quicksale/action`, { ...formData, type: "BUY" });
                console.log(res.data)
                const { amount, total, fee } = res.data;
                const title = i18n.t('quicksale.quickbuy_transaction_summary_title');
                commit('SET_QUICKSALE_TS_MODAL', {
                    isVisible: true,
                    title,
                    quantity: amount,
                    amount: total,
                    commission: fee,
                })
                Vue.$toast.success(getSuccessServiceMsg(res));
                dispatch('GET_QUICKSALE_HISTORY', { currencyCode: formData.currency, page: 1 });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
        async POST_SELL({ commit, dispatch }, { formData = {} }) {
            try {
                const res = await ServicesOld.post(`finance/quicksale/action`, { ...formData, type: "SELL" });
                console.log(res.data)
                const { amount, total, fee } = res.data;
                const title = i18n.t('quicksale.quicksell_transaction_summary_title');
                commit('SET_QUICKSALE_TS_MODAL', {
                    isVisible: true,
                    title,
                    quantity: amount,
                    amount: total,
                    commission: fee,
                })
                Vue.$toast.success(getSuccessServiceMsg(res));
                dispatch('GET_QUICKSALE_HISTORY', { currencyCode: formData.currency, page: 1 });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
        async GET_QUICKSALE_HISTORY({ commit }, { currencyCode, page = 1 }) {
            commit('IS_LOADING', { type: 'historyLoading', value: true });
            try {
                const res = await ServicesOld.get(`finance/quicksale/history?currency=${currencyCode}&page=${page}`)
                const payload = {
                    historyData: res?.data?.data || [],
                    historyLinks: res?.data?.links || {},
                    historyMeta: res?.data?.meta || {},
                    page
                }
                commit('SET_QUICKSALE_HISTORY', payload)
            } catch (err) {
                console.log('store/modules/quicksale@GET_QUICKSALE_HISTORY error: ', err)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'historyLoading', value: false });
            }
        },
    },
    namespaced: true
}

export default quicksale;
