import Vue from 'vue';
import ServicesOld from "@/config/_axios_old";
import API_OLD from '@/api/index_old';
//import i18n from '@/config/_i18n';
import { getErrorServiceMsg } from '../../utils';

const deposit = {
  state: () => ({
    providersLoading: false,
    providers: [],
    historyLoading: false,
    historyData: [],
    historyLinks: {},
    historyMeta: {},
    coinLabel: '',
    coinAddress: '',
    transferLoading: false,
    transfer: null
  }),
  mutations: {
    SET_PROVIDERS(state, payload) {
      state.providers = payload;
    },
    IS_LOADING(state, payload) {
      state[payload.type] = payload.value;
    },
    SET_HISTORY(state, payload) {
      state.historyData = payload.page === 1 ? payload.historyData : [...state.historyData, ...payload.historyData];
      state.historyLinks = payload.historyLinks;
      state.historyMeta = payload.historyMeta;
    },
    SET_COINS(state, payload) {
      state.coinLabel = payload.coinLabel;
      state.coinAddress = payload.coinAddress;
    },
    SET_TRANSFER(state, payload) {
      state.transfer = payload;
    }
  },
  actions: {
    async GET_PROVIDERS({ commit }, {currencyCode, isCrypto = false}) {
      commit('IS_LOADING', { type: 'providersLoading', value: true });
      try {
        const res = await ServicesOld.get(`${API_OLD.deposit_providers}/${currencyCode}`)
        if(isCrypto) {
          const cryptoProviders = res?.data?.data?.data?.crypto_currency?.providers;
          if (cryptoProviders && cryptoProviders.length !== 0) {
            const payload = {
              coinLabel: cryptoProviders[0].name + ' ' + 'address',
              coinAddress: cryptoProviders[0].address
            }
            commit("SET_COINS", payload);
          } else {
            commit("SET_COINS", {
              coinLabel: '',
              coinAddress: ''
            });
          }
        } else {
          commit("SET_PROVIDERS", res?.data?.data?.data?.bank_transfers?.providers || [])
        }
      } catch (err) {
        console.log('store/modules/deposit@GET_PROVIDERS error: ', err.response)
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      } finally {
        commit('IS_LOADING', { type: 'providersLoading', value: false });
      }
    },
    async GET_TRANSACTIONHISTORY({ commit }, { currencyCode, page = 1 }) {
      commit('IS_LOADING', { type: 'historyLoading', value: true });
      try {
        const res = await ServicesOld.get(`${API_OLD.deposit_transaction_history}/${currencyCode}?page=${page}`)
        // console.log('GET_TRANSACTIONHISTORY', res.data?.data);
        const payload = { 
          historyData: res?.data?.data?.data || [],
          historyLinks: res?.data?.data?.links || {},
          historyMeta: res?.data?.data?.meta || {},
          page
        }
        commit('SET_HISTORY', payload)
      } catch (err) {
          console.log('store/modules/deposit@GET_TRANSACTIONHISTORY error: ', err)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      } finally {
        commit('IS_LOADING', { type: 'historyLoading', value: false });
      }
    },
    async CREATE_DEPOSIT({ commit }, formData) {
      commit('IS_LOADING', { type: 'transferLoading', value: true });
      try {
        const res = await ServicesOld.post(`${API_OLD.deposit_create}`, formData);
        console.log('CREATE_DEPOSIT', res?.data?.data?.data);
        commit("SET_TRANSFER", res?.data?.data?.data || null);
      } catch (err) {
          console.log('store/modules/deposit@CREATE_DEPOSIT error: ', err.response?.data)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      } finally {
        commit('IS_LOADING', { type: 'transferLoading', value: false });
      }
    },
    async APPROVE_DEPOSIT({ commit, dispatch }, { transferCode, currencyCode }) {
      try {
        const res = await ServicesOld.put(`${API_OLD.deposit_approve}/${transferCode}`, {});
        console.log('APPROVE_DEPOSIT', res?.data?.data?.data);
        commit("SET_TRANSFER", null);
        dispatch('GET_TRANSACTIONHISTORY', { currencyCode, page: 1 })
      } catch (err) {
          console.log('store/modules/deposit@APPROVE_DEPOSIT error: ', err.response?.data)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      }
    },
    async CANCEL_DEPOSIT({ commit, dispatch }, { transferCode, currencyCode }) {
      commit("SET_TRANSFER", null);
      dispatch('GET_TRANSACTIONHISTORY', { currencyCode, page: 1 })
      console.log(transferCode)
    },
    async CANCEL_DEPOSIT_REQUEST({ commit, dispatch }, { transferCode, currencyCode }) {
      try {
        const res = await ServicesOld.delete(`${API_OLD.deposit_cancel}/${transferCode}`);
        console.log('CANCEL_DEPOSIT', res?.data?.data?.data);
        commit("SET_TRANSFER", null);
        dispatch('GET_TRANSACTIONHISTORY', { currencyCode, page: 1 })
      } catch (err) {
          console.log('store/modules/deposit@CANCEL_DEPOSIT error: ', err.response?.data)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      }
    },
    async CREATE_WALLET_ONE({dispatch}, {currencyCode}) {
      try {
        await ServicesOld.get(`${API_OLD.deposit_assign}/${currencyCode}`);
        dispatch("GET_PROVIDERS", { isCrypto: true, currencyCode })
      } catch (err) {
          console.log('store/modules/deposit@CREATE_WALLET_ONE error: ', err.response?.data)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      }
    },
  },
  namespaced: true
}

export default deposit;
