import { io } from 'socket.io-client';
const socketURL = process.env.VUE_APP_OFINANS_WS;

class SocketIOService {
  socket;
  constructor() {
    this.socket = io(socketURL, {autoConnect: false});
  }
  init() {
    this.socket.connect();
    this.socket.on('connect', () => {
      this.socket.emit('authenticate', { token: window.localStorage.getItem('userToken'), type: 'panel' });
    });
  }
  disconnect() {
    if(this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }
}

export const SockIOService = new SocketIOService();

