import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
import router from '../../router/index';

const currenciesManagement = {
    state: () => ({
        isLoading: false,
        isSubmitting: false,
        currencies: [],
        currencyEdit: {}
    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_FINANCE_CRM_CURRENCIES({ commit }, isLoading = true) {
            isLoading && commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.financecrm_currencies);
                const resCurrencies = Object.entries(res.data?.currencies);
                let data = [];
                if (resCurrencies.length > 0) {
                    data = resCurrencies.map(([key, value]) => ({ key, ...value }))
                }
                commit('SET_STATE', { name: 'currencies', value: data });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async GET_EDIT({ commit }, id) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.financecrm_currencies_edit(id));
                commit('SET_STATE', { name: 'currencyEdit', value: res.data?.currency ?? {} });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async UPDATE({ commit }, params) {
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const { id, formData } = params;
                const res = await ServicesOld.patch(API_OLD.financecrm_currencies_edit(id), formData);
                Vue.$toast.success(getSuccessServiceMsg(res));
                router.push({ name: 'Currencies' })
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async BALANCE_ACTIVE_PASSIVE_TOGGLE({ dispatch }, params) {
            try {
                const { id, type } = params;
                const url = type === 'activate' ?
                    API_OLD.financecrm_currencies_balance_activate(id) :
                    API_OLD.financecrm_currencies_balance_make_passive(id);
                const res = await ServicesOld.get(url);
                dispatch('GET_FINANCE_CRM_CURRENCIES', false);
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
    },
    namespaced: true
}

export default currenciesManagement;