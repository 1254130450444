import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';

const ratesManagement = {
    state: () => ({
        isLoading: false,
        supplierPrices: [],
        defaultCurrency: {},
        ratesHistoryLoading: false,
        ratesHistory: []
    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_RATES({ commit }, isLoading = true) {
            isLoading && commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.financecrm_currencies_rates);
                commit('SET_STATE', { name: 'supplierPrices', value: res.data?.cryptoCurrencies ?? [] });
                commit('SET_STATE', { name: 'defaultCurrency', value: res.data?.defaultCurrency ?? {} });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async UPDATE_RATE({ dispatch }, formData) {
            try {
                const res = await ServicesOld.post(API_OLD.financecrm_currencies_rates, formData);
                dispatch('GET_RATES', false);
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
        async GET_RATES_HISTORY({ commit }, formData) {
            commit('SET_STATE', { name: 'ratesHistoryLoading', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.financecrm_currencies_rates_history, formData);
                const currencyRates = res.data?.data || [];
                const baseCurrencyRates = res.data?.baseCurrencyRates || [];
                const formattedData = currencyRates.map((item, index) => {
                    const _buying = parseFloat(currencyRates[index]['buying']) / parseFloat(baseCurrencyRates[index]['buying'])
                    const _selling = parseFloat(currencyRates[index]['selling']) / parseFloat(baseCurrencyRates[index]['selling'])
                    return {
                        ...item,
                        buying: `${_buying.toFixed(5)}`,
                        selling: `${_selling.toFixed(5)}`,
                    }
                })
                commit('SET_STATE', { name: 'ratesHistory', value: formattedData });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'ratesHistoryLoading', value: false });
            }
        },
    },
    namespaced: true
}

export default ratesManagement;