<template>
  <div id="kt_header" class="header header-fixed">
    <!--begin::Container-->
    <div
      class="container-fluid d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Header Menu Wrapper-->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        id="kt_header_menu_wrapper"
      >
        <!--begin::Header Menu-->
        <div
          id="kt_header_menu"
          class="header-menu header-menu-mobile header-menu-layout-default"
        >
          <!--begin::Header Nav-->
          <div class="brand flex-column-auto" id="kt_brand">
            <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span class="svg-icon svg-icon svg-icon-xl">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-left.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
                    />
                    <path
                      d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </button>
          </div>

          <!--end::Header Nav-->
        </div>
        <!--end::Header Menu-->
      </div>
      <!--end::Header Menu Wrapper-->
      <!--begin::Topbar-->
      <div class="topbar">
        <!--begin::Search-->
        <div class="dropdown" id="kt_quick_search_toggle">
          <!--begin::Toggle-->
          <div
            class="topbar-item"
            data-toggle="dropdown"
            data-offset="10px,0px"
            :title="$t('commons.quick_customer_search')"
          >
            <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div
            class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
          >
            <div
              class="quick-search quick-search-dropdown"
              id="kt_quick_search_dropdown"
            >
              <!--begin:Form-->
              <form class="quick-search-form border-bottom border-bottom-primary">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <span class="svg-icon svg-icon-lg">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('commons.quick_customer_search')"
                    v-model="searchStr"
                  />
                  <div class="mr-5" :class="{'spinner spinner-sm spinner-primary': isSearching}">
                  </div>
                </div>
              </form>
              <!--end::Form-->
              <!--begin::Scroll-->
              <div
                class="searchScroll"
                data-scroll="true"
                data-height="325"
                data-mobile-height="200"
                v-if="searchData.length > 0"
              >
                <!--begin::Item-->
                <div class="d-flex align-items-center my-5" v-for="user in searchData" :key="user.id">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-25 symbol-light-success mr-5">
                    <span class="symbol-label">
                      <i class="fa fa-user"></i>
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                    <a href="javascript:void(0)" @click="goToUserDetails(user.id)" class="text-dark text-hover-primary mb-1 font-size-lg">
                      {{ user.name }} {{ user.surname }}
                    </a>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Scroll-->
            </div>
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Search-->

        <!--begin::Exchange Rates-->
        <div class="dropdown">
          <!--begin::Toggle-->
          <div
            class="topbar-item"
            data-toggle="dropdown"
            data-offset="10px,0px"
          >
            <div
              class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
              :class="{'pulse pulse-primary' : dummyExchangeRates.length > 0}"
            >
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="fa fa-coins text-primary"></i>
              </span>
              <span class="pulse-ring"></span>
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div
            class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
          >
            <form>
              <!--begin::Header-->
              <div
                class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style="background-image: url(/assets/media/misc/bg-1.jpg)"
              >
                <!--begin::Title-->
                <h4 class="d-flex flex-center rounded-top">
                  <span class="text-white">{{ $t('rates_management.exchange_rates') }}</span>
                </h4>
                <!--end::Title-->
              </div>
              <!--end::Header-->
              <!--begin::Content-->
              <div class="tab-content">
                <!--begin::Nav-->
                <div
                  class="navi navi-hover scroll my-4 notifications min-h-225px"
                  data-scroll="true"
                  data-height="300"
                  data-mobile-height="200"
                >
                  <!--begin::No Data-->
                  <p class="text-center mt-10">{{ $t('commons.no_data_available') }}</p>
                  <!--end::No Data-->
                  
                  <!--begin::Item-->
                  <a href="#" class="navi-item" v-for="(excItem,excIdx) in dummyExchangeRates" :key="excIdx">
                    <div class="navi-link">
                      <div class="navi-icon mr-2">
                        <i class="flaticon2-notification text-primary"></i>
                      </div>
                      <div class="navi-text" v-if="excItem.name">
                        <div class="font-weight-bold">
                          {{excItem.name}}
                        </div>
                        <div class="text-muted">{{excItem.desc}}</div>
                      </div>
                    </div>
                  </a>
                  <!--end::Item-->
                </div>
                <!--end::Nav-->
                <!--begin::Action-->
                <div class="d-flex flex-center pb-3 hide" v-if="false">
                  <a href="#" class="btn btn-light-primary font-weight-bold text-center">Daha Fazlası</a>
                </div>
                <!--end::Action-->
              </div>
              <!--end::Content-->
            </form>
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Exchange Rates-->

        <!--begin::Quick Actions-->
        <div class="dropdown">
          <!--begin::Toggle-->
          <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" :title="$t('commons.quick_actions')">
            <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5" />
                    <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5" />
                    <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5" />
                    <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <!--begin:Header-->
            <div class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top" style="background-image: url(/assets/media/misc/bg-1.jpg)">
              <h4 class="text-white font-weight-bold">{{ $t('commons.quick_actions') }}</h4>
            </div>
            <!--end:Header-->
            <!--begin:Nav-->
            <div class="row row-paddingless">
              <!--begin:Item-->
              <div class="col-12">
                <router-link to="/finance/deposit" class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom">
                  <span class="svg-icon svg-icon-3x svg-icon-success">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Euro.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M4.3618034,10.2763932 L4.8618034,9.2763932 C4.94649941,9.10700119 5.11963097,9 5.30901699,9 L15.190983,9 C15.4671254,9 15.690983,9.22385763 15.690983,9.5 C15.690983,9.57762255 15.6729105,9.65417908 15.6381966,9.7236068 L15.1381966,10.7236068 C15.0535006,10.8929988 14.880369,11 14.690983,11 L4.80901699,11 C4.53287462,11 4.30901699,10.7761424 4.30901699,10.5 C4.30901699,10.4223775 4.32708954,10.3458209 4.3618034,10.2763932 Z M14.6381966,13.7236068 L14.1381966,14.7236068 C14.0535006,14.8929988 13.880369,15 13.690983,15 L4.80901699,15 C4.53287462,15 4.30901699,14.7761424 4.30901699,14.5 C4.30901699,14.4223775 4.32708954,14.3458209 4.3618034,14.2763932 L4.8618034,13.2763932 C4.94649941,13.1070012 5.11963097,13 5.30901699,13 L14.190983,13 C14.4671254,13 14.690983,13.2238576 14.690983,13.5 C14.690983,13.5776225 14.6729105,13.6541791 14.6381966,13.7236068 Z" fill="#000000" opacity="0.3" />
                        <path d="M17.369,7.618 C16.976998,7.08599734 16.4660031,6.69750122 15.836,6.4525 C15.2059968,6.20749878 14.590003,6.085 13.988,6.085 C13.2179962,6.085 12.5180032,6.2249986 11.888,6.505 C11.2579969,6.7850014 10.7155023,7.16999755 10.2605,7.66 C9.80549773,8.15000245 9.45550123,8.72399671 9.2105,9.382 C8.96549878,10.0400033 8.843,10.7539961 8.843,11.524 C8.843,12.3360041 8.96199881,13.0779966 9.2,13.75 C9.43800119,14.4220034 9.7774978,14.9994976 10.2185,15.4825 C10.6595022,15.9655024 11.1879969,16.3399987 11.804,16.606 C12.4200031,16.8720013 13.1129962,17.005 13.883,17.005 C14.681004,17.005 15.3879969,16.8475016 16.004,16.5325 C16.6200031,16.2174984 17.1169981,15.8010026 17.495,15.283 L19.616,16.774 C18.9579967,17.6000041 18.1530048,18.2404977 17.201,18.6955 C16.2489952,19.1505023 15.1360064,19.378 13.862,19.378 C12.6999942,19.378 11.6325049,19.1855019 10.6595,18.8005 C9.68649514,18.4154981 8.8500035,17.8765035 8.15,17.1835 C7.4499965,16.4904965 6.90400196,15.6645048 6.512,14.7055 C6.11999804,13.7464952 5.924,12.6860058 5.924,11.524 C5.924,10.333994 6.13049794,9.25950479 6.5435,8.3005 C6.95650207,7.34149521 7.5234964,6.52600336 8.2445,5.854 C8.96550361,5.18199664 9.8159951,4.66400182 10.796,4.3 C11.7760049,3.93599818 12.8399943,3.754 13.988,3.754 C14.4640024,3.754 14.9609974,3.79949954 15.479,3.8905 C15.9970026,3.98150045 16.4939976,4.12149906 16.97,4.3105 C17.4460024,4.49950095 17.8939979,4.7339986 18.314,5.014 C18.7340021,5.2940014 19.0909985,5.62999804 19.385,6.022 L17.369,7.618 Z" fill="#000000" />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">{{ $t('deposit.name') }}</span>
                  <span class="d-block text-dark-50 font-size-lg">{{ $t('f_management.all_deposit_transactions') }}</span>
                </router-link>
              </div>
              <!--end:Item-->
              <!--begin:Item-->
              <div class="col-12">
                <router-link to="/users" class="d-block py-10 px-5 text-center bg-hover-light">
                  <span class="svg-icon svg-icon-3x svg-icon-success">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">{{ $t('c_management.customers') }}</span>
                  <span class="d-block text-dark-50 font-size-lg">{{ $t('c_management.all_customer') }}</span>
                </router-link>
              </div>
              <!--end:Item-->
            </div>
            <!--end:Nav-->
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Quick Actions-->

        <!--begin::Notifications-->
        <div class="dropdown">
          <!--begin::Toggle-->
          <div
            class="topbar-item"
            data-toggle="dropdown"
            data-offset="10px,0px"
          >
            <div
              class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
              :class="{'pulse pulse-primary': notificationsNewLength > 0}"
            >
              <div
                v-if="notificationsNewLength > 0"
                class="unread-notification-icon bg-danger rounded"
              >
              </div>
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="flaticon2-notification text-primary"></i>
              </span>
              <span class="pulse-ring"></span>
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div
            class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
          >
            <form>
              <!--begin::Header-->
              <div
                class="d-flex flex-column py-6 bgi-size-cover bgi-no-repeat rounded-top"
                style="background-image: url(/assets/media/misc/bg-1.jpg)"
              >
                <!--begin::Title-->
                <h4 class="d-flex flex-row px-3 align-items-center rounded-top">
                  <span class="text-white">{{ $t('commons.notifications') }}</span>
                  <span
                    class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
                  >
                    {{ $t('commons.number_new', {number: notificationsNewLength}) }}
                  </span>
                </h4>
                <div class="d-flex flex-row justify-content-between">
                  <button
                    type="button"
                    class="btn btn-text text-white btn-sm font-weight-bold btn-font-md"
                    :disabled="notificationsLoading || notificationsNewLength === 0"
                    @click="readNotifications"
                    :class="{'spinner spinner-track spinner-primary spinner-sm spinner-right': notificationsLoading}"
                  >
                    <i class="la" :class="[notificationsNewLength > 0 ? 'la-envelope text-success' : 'la-envelope-open text-white']"></i>
                    {{ $t('commons.mark_as_read') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-text text-white btn-sm font-weight-bold btn-font-md"
                    :disabled="notificationsLoading"
                    @click="refreshNotifications"
                    :title="$t('commons.refresh')"
                    :class="{'spinner spinner-track spinner-primary spinner-sm spinner-left': notificationsLoading}"
                  >
                    {{ $t('commons.refresh') }}
                  </button>
                </div>
                <!--end::Title-->
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <div class="tab-content">
                <!--begin::Tabpane-->
                <div
                  class="tab-pane active show"
                  id="topbar_notifications_events"
                  role="tabpanel"
                >
                  <!--begin::Nav-->
                  <div
                    class="navi navi-hover scroll my-4 notifications"
                    data-scroll="true"
                    data-height="300"
                    data-mobile-height="200"
                  >
                  <p class="text-center mt-10" v-if="notificationsLoading">{{ $t('commons.data_loading') }}</p>
                  <template v-else>
                    <!--begin::No Data-->
                    <p class="text-center mt-10" v-if="notifications.length === 0">{{ $t('commons.no_data_available') }}</p>
                    <!--end::No Data-->
                    <!--begin::Item-->
                    <a href="#" class="navi-item" v-for="(item,idx) in notifications" :key="`${item.id}-${idx}`">
                      <div class="navi-link">
                        <div class="navi-icon mr-2">
                          <i class="flaticon2-notification" :class="[item.read_at ? 'text-muted' : 'text-success']"></i>
                        </div>
                        <div class="navi-text" v-if="item.data">
                          <div class="font-weight-bold">
                            {{item.data.title}}
                          </div>
                          <div class="text-muted">{{item.data.message}}</div>
                        </div>
                      </div>
                    </a>
                    <!--end::Item-->
                  </template>
                  </div>
                  <!--end::Nav-->
                  <!--begin::Action-->
                  <div class="d-flex flex-center pb-3" v-if="notificationsHasNext">
                    <button
                      type="button"
                      @click="loadMoreNotifications"
                      :disabled="notificationsFetching || notificationsLoading"
                      class="btn btn-light-primary font-weight-bold text-center"
                      :class="{'spinner spinner-white spinner-right': notificationsFetching}"
                    >
                      {{ $t('commons.load_more') }}
                    </button>
                  </div>
                  <!--end::Action-->
                </div>
                <!--end::Tabpane-->
              </div>
              <!--end::Content-->
            </form>
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Notifications-->

        <!--begin::Languages-->
        <div class="dropdown" v-if="checkPermission('crm.locale')">
          <!--begin::Toggle-->
          <div
            class="topbar-item"
            data-toggle="dropdown"
            data-offset="10px,0px"
          >
            <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
              <img
                class="h-20px w-20px rounded-sm"
                :src="selectedFlag()"
                alt=""
              />
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div
            class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
          >
            <!--begin::Nav-->
            <ul class="navi navi-hover py-4">
              <!--begin::Item-->
              <li class="navi-item">
                <a href="#" class="navi-link" @click="changeLanguage('en')">
                  <span class="symbol symbol-20 mr-3">
                    <img
                      src="/assets/media/svg/flags/226-united-states.svg"
                      alt=""
                    />
                  </span>
                  <span class="navi-text" :class="{'font-weight-bolder': lang === 'en'}">English</span>
                </a>
              </li>
              <!--end::Item-->
              <!--begin::Item-->
              <li class="navi-item active">
                <a href="#" class="navi-link" @click="changeLanguage('tr')">
                  <span class="symbol symbol-20 mr-3">
                    <img src="/assets/media/svg/flags/006-turkey.svg" alt="" />
                  </span>
                  <span class="navi-text" :class="{'font-weight-bolder': lang === 'tr'}">Türkçe</span>
                </a>
              </li>
              <!--end::Item-->
              <!--begin::Item-->

              <!-- <li class="navi-item">
                <a href="#" class="navi-link" @click="changeLanguage('de')">
                  <span class="symbol symbol-20 mr-3">
                    <img src="/assets/media/svg/flags/162-germany.svg" alt="" />
                  </span>
                  <span class="navi-text">German</span>
                </a>
              </li> -->

              <!--end::Item-->
              <!--begin::Item-->

              <!-- <li class="navi-item">
                <a href="#" class="navi-link" @click="changeLanguage('me')">
                  <span class="symbol symbol-20 mr-3">
                    <img
                      src="/assets/media/svg/flags/239-montenegro.svg"
                      alt=""
                    />
                  </span>
                  <span class="navi-text">Montenegrin</span>
                </a>
              </li> -->

              <!--end::Item-->
            </ul>
            <!--end::Nav-->
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Languages-->

        <!--begin::User-->
        <div class="dropdown">
          <!--begin::Toggle-->
          <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px">
            <div
              class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
                >{{ $t('hello') }},</span
              >
              <span
                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
                >{{ user.name }}</span
              >
              <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
                <span class="symbol-label">{{ user.id }}</span>
              </span>
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div
            class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0"
          >
            <!--begin::Header-->
            <div class="d-flex align-items-center p-8 rounded-top">
              <!--begin::Symbol-->
              <div class="symbol-list d-flex flex-wrap">
                <div class="symbol symbol-light-success mr-3">
                  <span class="symbol-label font-size-h5">{{ user.id }}</span>
                </div>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                {{ user.name }} {{ user.surname }}
              </div>
              <!--end::Text-->
            </div>
            <div class="separator separator-solid"></div>
            <!--end::Header-->
            <!--begin::Nav-->
            <div class="navi navi-spacer-x-0 pt-5">
              <!--begin::Item-->
              <router-link to="/my-profile" class="navi-item px-8">
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-calendar-3 text-success"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">{{ $t('my_profile.title') }}</div>
                    <div class="text-muted">
                      {{ $t('my_profile.description') }}
                    </div>
                  </div>
                </div>
              </router-link>
              <!--end::Item-->
              <!--begin::Item-->
              <router-link to="/password-change" class="navi-item px-8">
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-lock text-danger"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">{{ $t('password_change.title') }}</div>
                  </div>
                </div>
              </router-link>
              <!--end::Item-->
              <!--begin::Footer-->
              <div class="navi-separator mt-3"></div>
              <div class="navi-footer justify-content-end px-8 py-5">
                <a
                  @click="logoutBtn"
                  class="btn btn-light-primary font-weight-bold"
                  >{{ $t('commons.sign_out') }}</a
                >
              </div>
              <!--end::Footer-->
            </div>
            <!--end::Nav-->
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::User-->

      </div>
      <!--end::Topbar-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { changeLanguages } from "../../config/_i18n";
import _ from "lodash";
import i18n from '@/config/_i18n';
import {SockIOService} from '../../config/_ofinans_ws';

export default {
  name: "Header",
  data() {
    return{
      searchStr: '',
      dummyExchangeRates: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.customer.user,
      permissions: (state) => state.customer.permissions,
      isSearching: (state) => state.userquicksearch.searching,
      searchData: (state) => state.userquicksearch.data,
      notificationsLoading: (state) => state.customer.notifications.isLoading,
      notificationsFetching: (state) => state.customer.notifications.isFetching,
      notifications: (state) => state.customer.notifications.data,
      notificationsPage: (state) => state.customer.notifications.page,
      notificationsHasNext: (state) => state.customer.notifications.hasNext,
      notificationsNewLength: (state) => state.customer.notifications.data?.filter(val => !val?.read_at)?.length
    }),
    lang: () => i18n.locale,
  },
  methods: {
    ...mapActions("customer", ["GET_USER", "GET_PERMISSION", "fetchNotifications", "readNotifications"]),
    ...mapActions("userquicksearch", ["quicksearchAction"]),

    logoutBtn() {
      this.$store.commit("customer/SET_LOGOUT");
      this.$toast.error(i18n.t('service.token_expired'));
    },
    getTitle() {
      return this.$route.name;
    },
    changeLanguage(lang) {
      changeLanguages(lang, true);
    },
    selectedFlag() {
      switch(i18n.locale) {
        case 'en':
          return '/assets/media/svg/flags/226-united-states.svg';
        case 'tr':
          return '/assets/media/svg/flags/006-turkey.svg';
        case 'de':
          return '/assets/media/svg/flags/162-germany.svg';
        case 'me':
          return '/assets/media/svg/flags/239-montenegro.svg';
      }
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
    searchCustomer: _.debounce(function(str) {
      this.quicksearchAction(str)
    }, 700),

    goToUserDetails(user_id) {
      this.$router.push({ path: `/users/${user_id}` })
    },
    loadMoreNotifications() {
      if(this.notificationsHasNext) {
        this.fetchNotifications(this.notificationsPage+1);
      }
    },
    refreshNotifications() {
      this.fetchNotifications(1);
    },
    async onReadNotifications() {
      if(this.notificationsNewLength === 0){
        return;
      }
      await this.readNotifications();
      await this.fetchNotifications(1);
    }

  },
  created() {
    // console.log("lang:",this.lang);
    this.GET_USER();
    this.GET_PERMISSION();
    this.fetchNotifications(1);
    SockIOService.init();
    SockIOService.socket.on('notifications', (data) => {
      this.$store.dispatch('customer/onNewNotification', data);
    });
  },
  destroyed() {
    SockIOService.disconnect();
  },
  watch: {
      searchStr(newVal){
        this.searchCustomer(newVal)
      }
  },
};
</script>
<style scoped>
  .searchScroll {
    height: 325px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
  }
  .notifications {
    max-height: 325px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .hide {
    display: none;
  }
  .unread-notification-icon {
    position: absolute;
    width: 7px;
    height: 7px;
    top: 10px;
    right: 10px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #c0c0c0;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background:#c0c0c0;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#e0e0e0;
  }
</style>
