import axios from 'axios';
import i18n from '@/config/_i18n';

const BASE_API_URL = process.env.VUE_APP_DEAL_API_URI;
const ServicesDeal = axios.create({ baseURL: String(BASE_API_URL) });

//let lang = sessionStorage.getItem('lang') || 'en';

ServicesDeal.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        //config.headers.common['X-Localization'] = lang;
        return config;
    },
    error => Promise.reject(...error)
);

ServicesDeal.interceptors.response.use(
    (response) => response,
    (error) => {
      let errorMessage = error.response?.data?.message || i18n.t('service.fatal_error');
      if (error.response.status === 401) {
        errorMessage = i18n.t('service.token_expired');
      }
      if (error.response.status === 404 || error.response.status === 405 || error.response.status === 500) {
        errorMessage = i18n.t('service.fatal_error');
      }
      throw {
        ...error,
        response: {
          ...error.response,
          data: {
            ...error.response.data,
            message: errorMessage
          }
        }
      };
    }
);

export default ServicesDeal;